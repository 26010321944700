import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { DatalayerItem, TrackingService } from '@rocheglobal/component-library-125/src/services/tracking';

import {
  DomService,
  StoryblokService,
  LanguageService,
} from '../services';
import { EntryData } from './types';
import { getComponent, blokToComponent } from '../components';
import { GoogleTagManager } from '../components/custom/google-tag-manager';
import { FacebookPixel } from '../components/custom/facebook-pixel';
import { InternalTracking } from '../components/custom/internal-tracking';
import { Hotjar } from '../components/custom/hotjar';
import { LinkedInInsightsTag } from '../components/custom/linkedin-insights-tag';

interface TopicEntryData extends EntryData {
  langPrefix: string;
  topic: string;
}

interface EntryProps {
  pageContext: TopicEntryData;
}
interface TrackingProps {
  topic: string;
}

const searchModal = typeof document !== 'undefined' ? document.getElementById('global-modal') : null;
const RocheGlobalConfig = getComponent('roche-global-config') as React.ReactType;
const Header = 'roche-header' as React.ReactType;
const OffCanvas = 'roche-offcanvas-panel' as React.ReactType;
const Search = 'roche-search' as React.ReactType;
const MobileNavigation = 'roche-mobile-navigation' as React.ReactType;

const trackPageView = ({ topic }: TrackingProps): void => {
  /* eslint-disable @typescript-eslint/camelcase */
  TrackingService.push({
    event: 'page_view',
    additionalParameters: [{
      page_type: `${topic} page`,
      page_category: 'page',
    }],
  } as DatalayerItem);
  /* eslint-enable @typescript-eslint/camelcase */
};

// eslint-disable-next-line import/no-default-export
const TopicPage = ({ pageContext }: EntryProps): JSX.Element => {
  const [languages, setLanguages] = useState([]);
  const [isSearchActive, setSearchActive] = useState(false);
  const {
    topic,
    story,
    googleTagManagerId,
    settings,
    navigation,
    footer,
    onClickNotice,
    search,
    langPrefix,
  } = pageContext;

  const canShowFooter = !story.content.hide_footer;
  const tagPageUrl = `${process.env.GATSBY_WEBSITE_URL}/${langPrefix ? `${langPrefix}/` : ''}topic`;

  const handleSearchModalTrigger = (event): void => {
    if (event.detail === 'roche-search') {
      setSearchActive(true);
    }
  };

  const handleCloseSearchModalTrigger = (): void => {
    setSearchActive(false);
  };

  useEffect(() => {
    LanguageService.getLanguages()
      .then((langs) => setLanguages(langs));
    window.addEventListener('rocheLoginComplete', () => StoryblokService.redirect());
    DomService.activateConsentScript();
    trackPageView({ topic });

    return function cleanup(): void {
      window.removeEventListener('rocheLoginComplete', () => StoryblokService.redirect());
    };
  }, []);

  useEffect(() => {
    window.addEventListener('rocheModalTrigger', handleSearchModalTrigger);
    window.addEventListener('rocheModalClose', handleCloseSearchModalTrigger);
  }, []);

  return (
    <>
      <GoogleTagManager
        googleTagManagerId={googleTagManagerId}
      ></GoogleTagManager>
      <FacebookPixel />
      <InternalTracking />
      <Hotjar />
      <LinkedInInsightsTag />
      <RocheGlobalConfig
        {...DomService.getGlobalConfig(story, settings)}
        tagPageUrl={tagPageUrl}
      ></RocheGlobalConfig>
      <OffCanvas id="roche-offcanvas-menu">
        <MobileNavigation
          languages={JSON.stringify(languages)}
        >
          {navigation
            && blokToComponent({ blok: navigation?.content, getComponent })}
        </MobileNavigation>
      </OffCanvas>
      {isSearchActive
        ? ReactDOM.createPortal(<Search
          id="roche-search"
          total-results-for-query={search?.content?.total_results_for_query}
        />, searchModal)
        : null
      }
      {<Header
        languages={JSON.stringify(languages)}
        hide-language-switch={story.content.hide_language_switch}
      >
        {navigation
        && blokToComponent({ blok: navigation?.content, getComponent })}
      </Header>}
      {blokToComponent({ blok: story.content, getComponent, storyId: story.id })}
      {canShowFooter && footer && blokToComponent({ blok: footer.content, getComponent })}
      {onClickNotice && blokToComponent({ blok: onClickNotice.content, getComponent })}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default TopicPage;
